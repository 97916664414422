import httpClient from '../utils/axios'
import {
  handleServiceResponse,
  handleServiceError,
} from '../utils/serviceResponseHelper'

const getEditableRegionInputTypeDescription = inputTypeId => {
  switch (inputTypeId) {
    case 1:
      return 'Free Text'
    case 2:
      return 'Date Picker'
    case 3:
      return 'Time Picker'
    case 4:
      return 'Drop Down'
    case 5:
      return 'Html Editor'
    case 6:
      return 'Carousel'
    case 7:
      return 'Address'
    default:
      return 'N/A'
  }
}

const getEditableRegionGroupInputTypeDescription = inputTypeId => {
  switch (inputTypeId) {
    case 1:
      return 'Address'
    default:
      return 'N/A'
  }
}

const minEditableNameLength = 3
const maxEditableNameLength = 100

const checkNameIsValid = (name, currentEditables) => {
  if (name.length < minEditableNameLength)
    return {
      isValid: false,
      message: `Name must be at least ${minEditableNameLength} characters`,
    }
  if (name.length > maxEditableNameLength)
    return {
      isValid: false,
      message: `Name must be a maximum of ${maxEditableNameLength} characters or fewer`,
    }
  if (currentEditables.find(x => x.name.toLowerCase() === name.toLowerCase()))
    return { isValid: false, message: 'Name already exists' }
  const regex = /^[a-z\d\-_\s]+$/i
  if (!regex.test(name)) {
    return {
      isValid: false,
      message:
        'Name can only contain alphanumeric characters, space and underscores',
    }
  }

  return { isValid: true, message: 'Valid Input' }
}

const checkTestDataIsValid = (testData, minLength, maxLength) => {
  if (!testData || testData.length < minLength)
    return {
      isValid: false,
      message: `Test Data must be at least ${minLength} characters`,
    }
  if (testData.length > maxLength)
    return {
      isValid: false,
      message: `Test Data must be a maximum of ${maxLength} characters or fewer`,
    }
  return { isValid: true, message: 'Valid Input' }
}

const checkInputTypeIdIsValid = inputTypeId => {
  if (inputTypeId === 0) return false
  return true
}

const checkMinLengthIsValid = (inputTypeId, minLength, maxLength) => {
  if (inputTypeId !== 1) return true
  if (minLength < 1) return false
  if (minLength > maxLength) return false
  return true
}

const checkMaxLengthIsValid = (inputTypeId, minLength, maxLength) => {
  if (inputTypeId !== 1) return true
  if (maxLength > 100) return false
  if (maxLength < minLength) return false
  return true
}

const checkOptionsAreValid = (inputTypeId, options) => {
  if (inputTypeId === 4 || inputTypeId === 6) {
    if (options.filter(x => !x.isArchived).length < 2) return false
  }
  return true
}

const checkEditableIsValid = (editable, currentEditables) => {
  return (
    checkNameIsValid(editable.name, currentEditables) &&
    checkInputTypeIdIsValid(editable.inputTypeId) &&
    checkMinLengthIsValid(
      editable.inputTypeId,
      editable.minLength,
      editable.maxLength
    ) &&
    checkMaxLengthIsValid(
      editable.inputTypeId,
      editable.minLength,
      editable.maxLength
    ) &&
    checkOptionsAreValid(editable.inputTypeId, editable.editableRegionOptions)
  )
}

const getEditableRegionGroups = async serviceId => {
  return httpClient
    .get(
      `${window.config.DOCUMENT_API_URL}/v1/editableregiongroup/getAllGroups?serviceId=${serviceId}`
    )
    .then(
      response => handleServiceResponse(response),
      error => handleServiceError(error)
    )
}

const getEditableRegionGroupById = async id => {
  return httpClient
    .get(
      `${window.config.DOCUMENT_API_URL}/v1/editableregiongroup/getgroupbyid?regionGroupId=${id}`
    )
    .then(
      response => handleServiceResponse(response),
      error => handleServiceError(error)
    )
}

const getEditableRegions = async serviceId => {
  return httpClient
    .get(
      `${window.config.DOCUMENT_API_URL}/v1/editableregion/getall?serviceId=${serviceId}`
    )
    .then(
      response => handleServiceResponse(response),
      error => handleServiceError(error)
    )
}

const getEditableRegion = async id => {
  return httpClient
    .get(
      `${window.config.DOCUMENT_API_URL}/v1/editableregion/get?regionId=${id}`
    )
    .then(
      response => handleServiceResponse(response),
      error => handleServiceError(error)
    )
}

const addEditableRegion = async newRegion => {
  return httpClient
    .post(
      `${window.config.DOCUMENT_API_URL}/v1/editableregion/create`,
      newRegion
    )
    .then(
      response => handleServiceResponse(response),
      error => handleServiceError(error)
    )
}

const updateEditableRegion = async updatedRegion => {
  return httpClient
    .put(
      `${window.config.DOCUMENT_API_URL}/v1/editableregion/update`,
      updatedRegion
    )
    .then(
      response => handleServiceResponse(response),
      error => handleServiceError(error)
    )
}

const getMergeFields = async () => {
  return httpClient
    .get(
      `${window.config.DOCUMENT_API_URL}/v1/editableregion/GetAllMergeFields`
    )
    .then(
      response => handleServiceResponse(response),
      error => handleServiceError(error)
    )
}

export {
  getEditableRegionInputTypeDescription,
  getEditableRegionGroupInputTypeDescription,
  checkNameIsValid,
  checkTestDataIsValid,
  checkInputTypeIdIsValid,
  checkMaxLengthIsValid,
  checkMinLengthIsValid,
  checkOptionsAreValid,
  checkEditableIsValid,
  getEditableRegions,
  getEditableRegion,
  addEditableRegion,
  updateEditableRegion,
  getMergeFields,
  getEditableRegionGroups,
  getEditableRegionGroupById,
}
