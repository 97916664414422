import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import AdminPageHeader from '../../../components/adminPageHeader'
import AdminBackdrop from '../../../components/adminBackdrop'
import DocumentTemplateForm from '../../../components/documentTemplateForm'

import templateService from '../../../services/documenttemplate.service'
import brandingService from '../../../services/documentbranding.service'
import { getEditableRegions } from '../../../services/editableregion.service'
import reviewTypeService from '../../../services/reviewType.service'
import { useService } from '../../../contexts/serviceContext'

const DocumentTemplateEdit = () => {
  let history = useHistory()
  let { id } = useParams()
  const { serviceId } = useService()

  const [isLoading, setIsLoading] = useState(false)

  const [brandings, setBrandings] = useState(null)
  const [reviewTypes, setReviewTypes] = useState([])
  const [editables, setEditables] = useState(null)

  const [initialFormData, setInitialFormData] = useState(null)

  useEffect(() =>{ 
    setIsLoading(true)
    templateService
      .getDocumentTemplate(parseInt(id), serviceId)
      .then(template => {
        setInitialFormData({
          id: template.id,
          name: template.name,
          serviceId: template.serviceId,
          isArchived: template.isArchived,
          brandingId:
            template.latestDocumentTemplateVersion?.documentTemplateBrandingId,
          contentHtml:
            template.latestDocumentTemplateVersion?.contentHtml || '',
          adminContentHtml:
            template.latestDocumentTemplateVersion?.adminContentHtml || '',
          liveDate: template.latestDocumentTemplateVersion?.liveDate,
          isAnytime: template.latestDocumentTemplateVersion?.isAnytime,
          isVisibleToProcessEngineBusinessUser: template.latestDocumentTemplateVersion?.isVisibleToProcessEngineBusinessUser,
          reviewTypeId: template.latestDocumentTemplateVersion?.reviewType
        })
      })
    brandingService
      .getDocumentBrandings(serviceId)
      .then(brandings => setBrandings(brandings))
      reviewTypeService
        .getReviewTypes()
        .then(reviewTypes => setReviewTypes(reviewTypes))
    getEditableRegions(serviceId)
      .then(regions => setEditables(regions))
  }, [id, serviceId])

  const fetchEditables = () => {
    getEditableRegions(serviceId)
      .then(regions => {
        setEditables(regions);
      })
      .catch(error => {
        console.error('Failed to fetch editables:', error);
      });
  };

  useEffect(() => {
    setIsLoading(!brandings || !editables || !initialFormData || !reviewTypes)
  }, [brandings, editables, initialFormData, reviewTypes])

  const handleSubmit = updateFormData => {
    setIsLoading(true)
    templateService.updateDocumentTemplate(updateFormData).then(() => {
      setIsLoading(false)
      history.push('/admin/template')
    })
  }

  return (
    <>
      <AdminBackdrop show={isLoading} />
      {!isLoading ? (
        initialFormData && brandings && editables && reviewTypes ? (
          <>
            <AdminPageHeader
              title={'Update Template'}
              navActionLabel='Return to templates'
              navActionPath='/admin/template'
            />
            <DocumentTemplateForm
              initialFormData={initialFormData}
              currentTemplates={[]}
              brandings={brandings}
              editables={editables}
              reviewTypes={reviewTypes}
              onSubmit={handleSubmit}
              fetchEditables={fetchEditables}
            />
          </>
        ) : null
      ) : null}
    </>
  )
}

export default DocumentTemplateEdit
